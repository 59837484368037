import { BackedAutocompleteItem } from '@/data/datatypes/components/AutocompleteItem';
import { Track } from '@/data/datatypes/Track';
import { FullUserDetails, LimitedUserDetails } from '@/data/datatypes/UserDetails';
import { useUserStore } from '@/stores/User';

export default class MeetingsHelper {
  public static isPstn(participantName: string): boolean {
    if (participantName.startsWith('+')) {
      return true;
    }

    if (participantName.replace(/[-+()\s]/g, '').match(/^\d+$/)) {
      return true;
    }

    return false;
  }

  public static formatMeetingNumber(meetingNumber: string): string | null {
    if (!meetingNumber) {
      return null;
    }
    let formatted: string = meetingNumber;
    if (formatted.length !== 10) {
      return formatted;
    }
    formatted = `${formatted.substring(0, 3)} ${formatted.substring(3, 6)} ${formatted.substring(6)}`;
    return formatted;
  }

  public static generateQuickDialUri(pstn: string, meetingNumber: string): string {
    const normalisedPstn = pstn.replaceAll(/[^+0-9]/g, '');
    const normalisedMeetingNumber = meetingNumber.replaceAll(/[^0-9]/g, '');
    // comma is a pause and the hash should submit the DTMF
    return `tel:${normalisedPstn},,${normalisedMeetingNumber}#`;
  }

  public track: Track;
  // TODO: Add typing for this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public invite: any;

  // TODO: Add typing for this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(track: Track, guestId: string | null,
    onInitialised?: (formattedMeetingNumber: string, invite: unknown) => void) {
    this.track = track;

    // TODO: Re-implement this through the shared worker (I think)
    // ServerData.getMeetingInviteText(track.id, guestId).then((invite) => {
    //   this.invite = invite;
    //   this.formattedMeetingNumber = MeetingsHelper.formatMeetingNumber(invite.meetingNumber);
    //   this.dialInNumbers = this.invite.dialInNumbers;
    //   if (onInitialised) {
    //     onInitialised(this.formattedMeetingNumber!, invite);
    //   }
    // });
    if (onInitialised) {
      onInitialised('TODO:', { guestId });
    }
  }

  public static getUserDisplayValue(user: LimitedUserDetails): string {
    let value: string;
    if (!user.displayName) {
      value = user.email || '';
    } else {
      value = user.displayName;
      if (user.displayName !== user.email) {
        value += ` <${user.email}>`;
      }
    }
    return value;
  }

  public static populateAutocompleteItems(result: BackedAutocompleteItem<string>[], user: LimitedUserDetails): void {
    const userStore = useUserStore();
    const currentUser: FullUserDetails | null = userStore.currentUser;
    if (currentUser?.id === user.id) {
      return;
    }
    const value: string = MeetingsHelper.getUserDisplayValue(user);
    const autocompleteItem: BackedAutocompleteItem<string> = {
      itemText: user.email,
      displayText: value,
      element: user.email,
      colour: user.color,
    };
    if (result.indexOf(autocompleteItem) < 0) {
      result.push(autocompleteItem);
    }
  }

  public static nextMeetingMessage(date: number): string {
    const until: number = date - new Date().getTime();
    if (until < 0) {
      return 'right now';
    } else if (until < 1000 * 60 * 60) {
      const mins: number = Math.floor(until / (1000 * 60));
      return mins + ' minute' + (mins !== 1 ? 's' : '');
    } else {
      const hours: number = Math.floor(until / (1000 * 60 * 60));
      const mins: number = Math.floor(until / (1000 * 60)) - 60 * hours;
      const hourText: string = hours + ' hour' + (hours !== 1 ? 's' : '');
      switch (mins) {
        case 0:
          return hourText;
        case 1:
          return hourText + ' 1 minute';
        default:
          return hourText + ' ' + mins + ' minutes';
      }
    }
  }
}
